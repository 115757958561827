import {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
const options = [{
    "name":"Support",
    "url":"#"

},{
    "name":"Account settings",
    "url":"#"
},{
    "name":"License",
    "url":"#"
}];
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DropDown({title}) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-faq px-5 py-5 text-xl font-poppins text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                    {title}
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true"/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items
                    className="absolute right-0 z-10 mt-2 w-full origin-top-right
                    rounded-md bg-faq shadow-lg ring-1 ring-black ring-opacity-5
                    focus:outline-none">

                    <div className="py-1">
                        {options.map((op,i)=>
                            <Menu.Item key={i}>
                                {({active}) => (
                                    <a
                                        href={op.url}
                                        className={classNames(
                                            active ? 'bg-faq text-gray-900' : 'text-gray-600',
                                            'block px-4 py-2 text-xl font-poppins'
                                        )}>
                                        {op.name}
                                    </a>
                                )}
                            </Menu.Item>)}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
